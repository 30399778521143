function initSlider(slider) {
    
    if (slider === null) {
        return
    }

    let defaultSliderOptions = {
        arrows: slider.querySelector(`.splide__arrows`) !== null,
        classes: {
            arrow: `slider__arrow`,
            arrows: `slider__arrows`,
            next: `slider__arrows--next`,
            prev: `slider__arrows--prev`
        },
        gap: `1.75rem`,
        lazyLoad: true,
        pagination: false,
        updateOnMove: true,
        perPage: 1
    }
    let perPageAttr = slider.getAttribute(`data-slides-per-page`)
    let sliderOptions = {}
    let testimonialsOptions = {}

    /**
     * Create breakpoints
     * Important: from smallest to largest width
     */
    if (typeof perPageAttr === `string`) {
        let breakpoints = {}
        let perPage = 3
        let gap = `1.75rem`

        perPageAttr = JSON.parse(perPageAttr.replace(/'/g, '"'))

        /**
         * Breakpoints
         */
        if (Object.entries(perPageAttr).indexOf(`small_screen`)) {
            let data = {};
            data.perPage = Number(perPageAttr.small_screen);
            if(slider.classList.contains('splide--products')) {
                data.gap = `2rem`;
            } 
            breakpoints[767] = data;
        }

        if (Object.entries(perPageAttr).indexOf(`medium_screen`)) {
            let data = {};
            data.perPage = Number(perPageAttr.medium_screen);
            if(slider.classList.contains('splide--products')) {
                data.gap = `3rem`;
            } 
            breakpoints[1023] = data;
        }

        if (Object.entries(perPageAttr).indexOf(`big_screen`)) {
            perPage = Number(perPageAttr.big_screen);
            if(slider.classList.contains('splide--products')) {
                gap = `7.5rem`;
            } 
        }

        /**
         * Options
         */
        
        sliderOptions = {
            breakpoints,
            perPage,
            gap
        }

        if(slider.classList.contains('splide--testimonials')) {
            testimonialsOptions = {
                pagination: true
            }
        } 
    }

    /**
     * Create slider
     */
    const splide = new Splide(slider, {...defaultSliderOptions, ...sliderOptions, ...testimonialsOptions})


    /**
     * Lazy load
     */
    splide.on(`lazyload:loaded`, img => {
        img.parentNode.style.backgroundImage = `url(${img.getAttribute(`src`)})`
        img.remove()
    })

    /**
     * Progress bar
     */
    const progress = splide.root.querySelector(`.slider__progress`)

    if (progress !== null) {
        splide.on(`mounted move`, () => {
            if (splide.Components.Slides.isEnough()) {
                const progressBar = progress.querySelector(`.slider__progress-bar`)

                if (progress.classList.contains(`progress-grow`)) {
                    const end = splide.Components.Controller.getEnd() + 1

                    progressBar.style.width = `${String(100 * (splide.index + 1) / end)}%`
                } else if (progress.classList.contains(`progress-move`)) {
                    const bar = {
                        translate: ((splide.index) * 100) / splide.options.perPage,
                        width: (splide.options.perPage / splide.Components.Slides.getLength()) * 100
                    }

                    progressBar.style.width = `${String(bar.width)}%`
                    progressBar.style.transform = `translateX(${String(bar.translate)}%)`
                }
            } else {
                progress.closest(`.slider__tools`).classList.add(`d-none`)
            }
        })
    }

    /**
     * Mount slider
     */
    splide.mount()
}

/**
 * Front
 */
document.addEventListener(`DOMContentLoaded`, () => {
    const sliders = document.querySelectorAll(`.slider`)

    if (sliders.length > 0) {
        sliders.forEach(slider => {
            initSlider(slider.querySelector(`.splide`))
        })
    }
})